import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import { UserProvider } from "./UserContext";
import { LocationProvider } from "./LocationContext";

import LoginPage from "./pages/Auth/LoginPage";

import RotaSidebar from "./components/Sidebar/RotaSidebar";
import Dashboard from "./components/Dashboard/Dashboard";
import Rotations from "./components/Rotations/Rotations";
import Documents from "./components/Documents/Documents";
import Messages from "./components/Messages/Messages";
import Students from "./components/Students/Students";
import Search from "./components/Search/Search";
import StudentRotations from "./components/Rotations/StudentRotations";
import Locations from "./components/Locations/Locations";
import Requests from "./components/Requests/Requests";
import Compliance from "./components/Compliance/Compliance";
import Evaluations from "./components/Evaluations/Evaluations";
import SubmitEvaluation from "./components/Evaluations/SubmitEvaluation";
import Reports from "./components/Reports/Reports";
import AlterDB from "./components/AlterDB";
import Card from "./components/Card/Card";
import AdminLogin from "./pages/AdminLogin";
import AdminPanel from "./pages/AdminPanel";
import AdminUniv from "./pages/AdminUniv";
import AdminLocation from "./pages/AdminLocation";
import AdminOpps from "./pages/AdminOpps";
import AdminUsers from "./pages/AdminUsers";
import AdminRotations from "./pages/AdminRotations";
import LandingPage from "./pages/LandingPage";
import AdminRequestForm from "./pages/AdminRequestForm";
import AdminLifecycle from "./pages/AdminLifecycle"; // Changed to match actual file name
import AdminTasks from "./pages/AdminTasks";
import PrivateRoute from "./PrivateRoute";
import AdminManagePermissions from "./pages/AdminManagePermissions";
import AdminContactList from "./pages/AdminContactList";
import SignupPage from "./pages/Auth/SignupPage";
import Payment from "./pages/Payment";

const Layout = React.memo(
  ({ isDarkMode, setIsDarkMode, isSidebarOpen, setIsSidebarOpen }) => {
    return (
      <>
        <RotaSidebar
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <div
          className={`flex-1 p-4 mt-14 ${
            isSidebarOpen ? "sm:ml-64" : ""
          } bg-white dark:bg-gray-900 text-gray-900 dark:text-white overflow-x-hidden`}
        >
          <div className="container mx-auto">
            <Outlet />
          </div>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isDarkMode === nextProps.isDarkMode &&
      prevProps.isSidebarOpen === nextProps.isSidebarOpen
    );
  }
);

const AppProviders = ({ children }) => {
  return (
    <UserProvider>
      <LocationProvider>{children}</LocationProvider>
    </UserProvider>
  );
};

function App() {
  // const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      return savedTheme === "dark";
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => {
      if (!localStorage.getItem("theme")) {
        setIsDarkMode(e.matches);
      }
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
      document.body.classList.add("dark-bg");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      document.body.classList.remove("dark-bg");
      localStorage.setItem("theme", "light");
    }
  }, [isDarkMode]);

  return (
    <AppProviders>
      <Router>
        <div
          className={`flex flex-col min-h-screen ${isDarkMode ? "dark" : ""}`}
        >
          <Routes>
            <Route path="/app/login" element={<LoginPage />} />
            <Route path="/app/signup" element={<SignupPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/app"
              element={
                <Layout
                  isDarkMode={isDarkMode}
                  setIsDarkMode={setIsDarkMode}
                  isSidebarOpen={isSidebarOpen}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              }
            >
              {/* after auth */}
              {/* <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewSearchPage"
                    navigatePath="/"
                  />
                }
              >
                <Route path="search" element={<Search />} />
              </Route> */}
              <Route path="/app/search" element={<Search />} />
              <Route path="/app/payment" element={<Payment />} />

              <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewRotation"
                    navigatePath="/"
                  />
                }
              >
                <Route
                  path="student-rotations"
                  element={<StudentRotations />}
                />
              </Route>

              {/* <Route
                path="payment"
                element={
                  <PrivateRoute
                    requiredPermission="makePayment"
                    navigatePath="/"
                  />
                }
              >
                <Route path="payment" element={<Payment />} />
              </Route> */}

              <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewRotation"
                    navigatePath="/"
                  />
                }
              >
                <Route path="rotations" element={<Rotations />} />
              </Route>

              <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewDashboard"
                    navigatePath="/"
                  />
                }
              >
                <Route path="dashboard" element={<Dashboard />} />
              </Route>

              <Route path="admin/login" element={<AdminLogin />} />
              <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewAdminPage"
                    navigatePath="admin/login"
                  />
                }
              >
                <Route path="admin/7x4b2k-h9z83p" element={<AdminPanel />} />
              </Route>
              <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewEvaluationsPage"
                    navigatePath="/"
                  />
                }
              >
                <Route path="evaluations" element={<Evaluations />} />
              </Route>
              <Route path="/app/reports" element={<Reports />} />

              {/* admin permissions */}
              <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewAdminPage"
                    navigatePath="admin/login"
                  />
                }
              >
                <Route
                  path="admin/7x4b2k-h9z83p/locations"
                  element={<AdminLocation />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/forms"
                  element={<AdminRequestForm />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/opportunities"
                  element={<AdminOpps />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/lifecycle"
                  element={<AdminLifecycle />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/tasks"
                  element={<AdminTasks />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/rotations"
                  element={<AdminRotations />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/users"
                  element={<AdminUsers />}
                />
              </Route>
              <Route
                element={
                  <PrivateRoute
                    requiredPermission="ALL"
                    navigatePath="admin/login"
                  />
                }
              >
                <Route
                  path="admin/7x4b2k-h9z83p/universities"
                  element={<AdminUniv />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/manage-permissions"
                  element={<AdminManagePermissions />}
                />
                <Route
                  path="admin/7x4b2k-h9z83p/contact-list"
                  element={<AdminContactList />}
                />
              </Route>

              {/* student routes */}
              <Route path="student/:studentId/search" element={<Search />} />
              <Route
                path="student/:studentId/rotations"
                element={<StudentRotations />}
              />

              {/* institution routes */}
              <Route
                element={
                  <PrivateRoute
                    requiredPermission="viewRequestsPage"
                    navigatePath="/app/login"
                  />
                }
              >
                <Route path="requests" element={<Requests />} />
              </Route>

              {/* university routes */}
              <Route
                path="university/:baseId/dashboard"
                element={<Dashboard user={{ name: "test" }} />}
              />
              <Route
                path="university/:baseId/:locationId/dashboard"
                element={<Dashboard user={{ name: "test" }} />}
              />
              <Route
                path="university/:baseId/rotations"
                element={<Rotations />}
              />
              <Route
                path="university/:baseId/:locationId/rotations"
                element={<Rotations />}
              />
              <Route path="university/:baseId/list" element={<Students />} />
              <Route
                path="university/:baseId/:locationId/list"
                element={<Students />}
              />
              <Route
                path="university/:baseId/locations"
                element={<Locations />}
              />
              <Route
                path="university/:baseId/:locationId/locations"
                element={<Locations />}
              />
              <Route
                path="university/:baseId/:locationId/card/:cardId"
                element={<Card />}
              />
              <Route
                path="university/:baseId/requests"
                element={<Requests />}
              />
              <Route
                path="university/:baseId/:locationId/requests"
                element={<Requests />}
              />
              <Route path="university/:baseId/reports" element={<Reports />} />
              <Route
                path="university/:baseId/:locationId/reports"
                element={<Reports />}
              />
              <Route path="university/:baseId/alterdb" element={<AlterDB />} />
              <Route
                path="university/:baseId/:locationId/alterdb"
                element={<AlterDB />}
              />
              <Route
                path="university/:baseId/evaluations"
                element={<Evaluations />}
              />
              <Route
                path="university/:baseId/:locationId/evaluations"
                element={<Evaluations />}
              />
              <Route
                path="university/:baseId/:locationId/evaluations/:rotationId"
                element={<SubmitEvaluation />}
              />

              {/* student routes */}
              <Route path="documents" element={<Documents />} />
              <Route path="compliance" element={<Compliance />} />
              <Route path="messages" element={<Messages />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </AppProviders>
  );
}

export default App;

// src/pages/LandingPage.jsx

import React, { useState } from "react";
import { addContactUser } from "../services/firebaseService"; // Adjust the path as needed
import logo from "./rota.png";
import bg from "./bg.jpeg";
import "./LandingPage.css";

const Section = ({ imageSrc, title, description }) => (
  <div className="flex flex-col md:flex-row items-center gap-8 md:gap-12">
    <div className="w-full md:w-1/2">
      <div className="aspect-square rounded-full overflow-hidden">
        <img
          src={imageSrc}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
    </div>
    <div className="w-full md:w-1/2 text-center md:text-left">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const LandingPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await addContactUser(formData);
      setSuccessMessage("Your information has been successfully submitted!");
      setFormData({ name: "", email: "", phone: "" });
      setOpenModal(false);
      setIsSuccess(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const rightArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 18l6-6-6-6" />
    </svg>
  );

  const rotaButton = (text, subText, onClick) => (
    <button
      className="border-2 border-[#0e9065] hover:pr-1 text-[#0e9065] py-1 px-6 text-left flex justify-between items-center h-14 text-sm lg:text-base"
      onClick={onClick || (() => {})}
    >
      <span
        className="flex flex-col leading-tight"
        style={{ letterSpacing: "0.1em" }}
      >
        <span>{text}</span>
        {subText && <span className="hidden lg:block text-sm">{subText}</span>}
      </span>
      <span className="text-[#0e9065] ml-2 text-xl font-bold">
        {rightArrow}
      </span>
    </button>
  );

  return (
    <div>
      <header
        className="bg-cover bg-center h-screen flex flex-col md:flex-row items-center md:justify-between px-4 md:px-12 text-white py-24 md:gap-12 lg:gap-32"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="w-full h-full md:w-1/2 flex flex-col justify-between md:py-16 items-start">
          <div className="flex flex-col items-center mb-12">
            <img
              src={logo}
              alt="ROTA Logo"
              className="w-[26rem] max-w-[90%] h-auto mb-6"
            />
            <p className="text-lg md:text-xl text-center">
              <div className="flex flex-col">
                <span>YOUR MEDICAL ROTATION AIRBNB.</span>
                <span>FIND YOUR ROTATION FAST & EASY</span>
              </div>
            </p>
          </div>

          <div className="grid grid-cols-2 gap-4 w-full max-w-xl">
            {rotaButton("APPLY FOR ROTA", "( WAITING LIST )", () =>
              setOpenModal(true)
            )}
            {rotaButton("FEATURES", "", () => {
              window.location.href = "#features";
            })}
            {rotaButton("HOW TO", "", () => {
              window.location.href = "#how-to";
            })}
            {rotaButton("CONTACT US", "", () => {
              window.location.href = "#contact";
            })}
          </div>
        </div>

        <div
          className="flex h-full md:w-1/2 flex-col space-y-8 mt-8 md:mt-0y"
          style={{ letterSpacing: "0.1em" }}
        >
          <h2 className="text-[#8f8f8f] leading-relaxed font-thin text-sm md:text-xl">
            WE ARE THE ALL-IN-ONE PLATFORM THAT SIMPLIFIES THE ROTATION
            EXPERIENCE FOR STUDENTS. WHETHER YOU'RE SEARCHING FOR CLINICAL
            TRAINING, SETTING YOUR SCHEDULE, OR TRACKING YOUR PROGRESS, ROTA HAS
            YOU COVERED. OUR PLATFORM CONNECTS YOU WITH TRAINING SITES,
            STREAMLINES BOOKINGS, AND KEEPS YOU ORGANIZED FROM START TO FINISH.
          </h2>
          <p
            className="text-xs md:text-md font-semibold"
            style={{ letterSpacing: "0.1em" }}
          >
            <div className="flex flex-col">
              <span>FOR:</span>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;. MEDICAL STUDENTS & GRADUATES
              </span>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;. HOSPITALS AND TRAINING CENTERS
              </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;. UNIVERSITIES AND COLLEGES</span>
            </div>
          </p>
        </div>
      </header>

      <div className="py-12 bg-gray-100" id="features">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:w-[80%] mb-12" style={{ letterSpacing: "0.1em" }}>
            <h2 className="text-3xl font-bold mb-2 text-[#0e9065]">
              POWERFUL TOOLS FOR A SEAMLESS ROTATION EXPERIENCE:
            </h2>
            <p className="text-gray-600 text-sm">
              ROTA EQUIPS STUDENTS WITH EVERYTHING THEY NEED TO FIND, BOOK, AND
              MANAGE THEIR ROTATIONS EFFORTLESSLY. OUR PLATFORM DELIVERS A
              STREAMLINED EXPERIENCE TO SIMPLIFY YOUR ROTATION JOURNEY, MAKING
              IT SMOOTHER AND MORE REWARDING.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <svg
                  className="h-12 w-12 text-[#0e9065]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div>
                <div className="bg-transparent border-2 border-[#0e9065] p-6 shadow-md mb-3">
                  <h3 className="text-xl font-semibold">
                    MAP-BASED ROTATION FINDER
                  </h3>
                </div>
                <p
                  className="text-gray-600 text-xs md:text-md"
                  style={{ letterSpacing: "0.1em" }}
                >
                  EASILY FIND TRAINING SITES ON AN INTERACTIVE MAP, FILTERING BY
                  SPECIALTY, LOCATION, AND AVAILABILITY TO FIND THE BEST FIT
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <svg
                  className="h-12 w-12 text-[#0e9065]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div>
                <div className="bg-transparent border-2 border-[#0e9065] p-6 shadow-md mb-3">
                  <h3 className="text-xl font-semibold">
                    AVAILABILITY & INSTANT BOOKING REQUESTS
                  </h3>
                </div>
                <p
                  className="text-gray-600 text-xs md:text-md"
                  style={{ letterSpacing: "0.1em" }}
                >
                  SET YOUR AVAILABLE TIMES AND SEND BOOKING REQUESTS DIRECTLY
                  THROUGH ROTA, WITHOUT WAITING ON EMAILS OR PHONE CALLS
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <svg
                  className="h-12 w-12 text-[#0e9065]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 4h-1V3a1 1 0 00-2 0v1H8V3a1 1 0 00-2 0v1H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2zM5 20V9h14v11H5zm0-13V6h14v1H5zm3 5h8m-8 4h6"
                  />
                </svg>
              </div>
              <div>
                <div className="bg-transparent border-2 border-[#0e9065] p-6 shadow-md mb-3">
                  <h3 className="text-xl font-semibold">
                    PERSONALIZED CALENDAR VIEW
                  </h3>
                </div>
                <p
                  className="text-gray-600 text-xs md:text-md"
                  style={{ letterSpacing: "0.1em" }}
                >
                  VISUALIZE YOUR ENTIRE ROTATION SCHEDULE IN A PERSONALIZED
                  CALENDAR. SEE ALL YOUR PLACEMENTS, DEADLINES, AND KEY DATES AT
                  A GLANCE
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <svg
                  className="h-12 w-12 text-[#0e9065]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
              <div>
                <div className="bg-transparent border-2 border-[#0e9065] p-6 shadow-md mb-3">
                  <h3 className="text-xl font-semibold">
                    EVALUATION & COMPLETION LETTERS
                  </h3>
                </div>
                <p
                  className="text-gray-600 text-xs md:text-md"
                  style={{ letterSpacing: "0.1em" }}
                >
                  RECEIVE FEEDBACK ON YOUR PERFORMANCE AND AN OFFICIAL
                  COMPLETION LETTER ONCE YOU FINISH EACH ROTATION, HELPING YOU
                  TRACK YOUR PROGRESS AND VALIDATE YOUR EXPERIENCE
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-12 bg-[#070c29]" id="how-to">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:w-[80%] mb-12" style={{ letterSpacing: "0.1em" }}>
            <h2 className="text-3xl font-bold mb-2 text-[#0e9065]">
              HOW TO SECURE YOUR ROTATION WITH ROTA:
            </h2>
            <p className="text-white text-sm">
              FIND THE PERFECT TRAINING OPPORTUNITY IN JUST A FEW STEPS! FROM
              BROWSING OPTIONS TO STARTING YOUR ROTATION, ROTA MAKES IT EASY FOR
              STUDENTS TO FIND AND SECURE THEIR PLACEMENTS.
            </p>
          </div>
        </div>

        <div className="py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-6 relative">
              {/* Dashed connecting lines for big screens */}
              <div
                className="hidden md:block absolute top-[2.5rem] left-[10%] right-[10%] border-t-2 border-dashed border-white"
                style={{ zIndex: 0 }}
              />

              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  CREATE YOUR FREE ACCOUNT
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  EXPLORING TRAINING OPPORTUNITIES
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 11H15M9 15H15M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  SEND BOOKING REQUESTS
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  PAY TO CONFIRM
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  STAY INFORMED & PREPARED
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  BEGIN YOUR ROTATION
                </p>
              </div>
              <div className="flex flex-col items-center relative z-10">
                <div className="p-4 rounded-full border-2 border-dashed border-white bg-[#070c29]">
                  <svg
                    className="h-8 w-8 text-[#0e9065]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 7V3m0 0L8 7m4-4l4 4m-4 4v10M4 15l8 8 8-8"
                    />
                  </svg>
                </div>
                <p className="text-center text-xs mt-2 text-white border border-[#0e9065] p-2">
                  RECEIVE COMPLETION LETTER
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#15b6be] py-16" id="contact">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col-reverse md:flex-row justify-between items-center gap-12">
            <div className="flex flex-col items-center md:items-start">
              <img src={logo} alt="ROTA Logo" className="w-48 h-auto mb-8" />
              <div className="flex flex-col space-y-4 text-white">
                <div className="flex items-center space-x-3">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <a href="mailto:hi@ehealth.sa" className="hover:underline">
                    hi@ehealth.sa
                  </a>
                </div>
                <div className="flex items-center space-x-3">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <a href="tel:+966-56-628-8666" className="hover:underline">
                    +966 56 628 8666
                  </a>
                </div>
              </div>

              <div className="flex space-x-6 mt-8">
                {/* <a href="#" className="text-white hover:text-gray-200">
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                  </svg>
                </a> */}
                <a href="https://x.com/ehealthksa" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200">
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/ehealthksa/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200">
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                  </svg>
                </a>
                {/* <a href="https://www.instagram.com/ehealth.sa/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200">
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                  </svg>
                </a> */}
              </div>
            </div>

            <div className="mt-12 flex justify-center">
              <button
                className="flex justify-between items-center w-[400px] bg-transparent border-2 text-left border-white text-white font-bold py-4 px-8 text-xl shadow-lg transform transition-transform hover:scale-105"
                onClick={() => setOpenModal(true)}
              >
                <div className="flex items-center">
                  <div
                    className="flex flex-col underline"
                    style={{ letterSpacing: "0.1em" }}
                  >
                    <p>Apply for ROTA</p>
                    <p>( Waiting list )</p>
                  </div>
                </div>
                <svg
                  className="h-6 w-6 ml-2 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </button>
            </div>

            {/* <div className="w-full md:w-1/2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.674754711687!2d46.6752!3d24.7136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDQyJzQ5LjAiTiA0NsKwNDAnMzAuNyJF!5e0!3m2!1sen!2ssa!4v1629291234567!5m2!1sen!2ssa"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                className="rounded-lg shadow-lg"
              ></iframe>
            </div> */}
          </div>
          {openModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-[#070c29] p-8 rounded-lg max-w-md w-full mx-4">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl text-white">Apply for ROTA</h2>
                  <button
                    onClick={() => setOpenModal(false)}
                    className="text-white hover:text-gray-300"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="block w-full p-2 mb-4 border border-gray-300"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="block w-full p-2 mb-4 border border-gray-300"
                    required
                  />
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    className="block w-full p-2 mb-4 border border-gray-300"
                    required
                  />
                  <button
                    type="submit"
                    className="w-full p-2 bg-[#0e9065] text-white hover:bg-[#0b7a55]"
                  >
                    Submit
                  </button>
                </form>
                {successMessage && (
                  <p className="text-center text-green-500 mt-4">
                    {successMessage}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#0e9065]"></div>
        </div>
      )}

      {isSuccess && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full mx-4">
            <div className="flex items-center justify-center mb-4">
              <svg
                className="w-16 h-16 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold text-center mb-2">Success!</h3>
            <p className="text-gray-600 text-center mb-6">{successMessage}</p>
            <button
              onClick={() => setIsSuccess(false)}
              className="w-full p-2 bg-[#0e9065] text-white rounded hover:bg-[#0b7a55]"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllForms,
  createForm,
  updateForm,
  deleteForm,
  getFormsByBaseId
} from "../services/firebaseService";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  CheckIcon,
  ExclamationCircleIcon,
  ArrowLeftIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import { LocationContext } from "../LocationContext";
import { UserContext } from "../UserContext";

const AdminRequestForm = () => {
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const { baseId } = useContext(LocationContext);
  const { permissions } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingForm, setEditingForm] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchForms();
  }, [baseId]);

  const fetchForms = async () => {
    setIsLoading(true);
    try {
      const hasAllPermission = permissions?.ALL;
      let formsSnapshot;
      if (hasAllPermission) {
        formsSnapshot = await getAllForms();
      } else {
        if (baseId) {
          console.log("Fetching forms for baseId: ", baseId);
          formsSnapshot = await getFormsByBaseId(baseId);
        }
      }
      setForms(formsSnapshot);
    } catch (error) {
      setErrorMessage("Error fetching forms: " + error.message);
    }
    setIsLoading(false);
  };

  const handleEdit = (form) => {
    setIsEditing(true);
    setEditingForm(form);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDelete = async (id) => {
    try {
      await deleteForm(id);
      fetchForms();
      setIsDeleteModalOpen(false);
    } catch (error) {
      setErrorMessage("Error deleting form: " + error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      const formWithBase = {
        ...editingForm,
        baseId: baseId
      };
      if (editingForm.id) {
        await updateForm(editingForm.id, formWithBase);
      } else {
        await createForm(formWithBase);
      }
      setIsEditing(false);
      setEditingForm(null);
      setIsSaveModalOpen(false);
      fetchForms();
    } catch (error) {
      setErrorMessage("Error saving form: " + error.message);
    }
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...editingForm.questions];
    newQuestions[index] = {
      ...newQuestions[index],
      [field]: value,
    };
    setEditingForm({
      ...editingForm,
      questions: newQuestions,
    });
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <>
      <div className="mb-4">
        <button
          onClick={() => window.history.back()}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          <ArrowLeftIcon className="h-5 w-5 inline-block mr-2" />
          Back
        </button>
      </div>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Request Forms
          </h1>
          <div className="mb-4">
            <button
              onClick={() => {
                setIsEditing(true);
                setEditingForm({
                  name: "",
                  questions: [
                    {
                      text: "",
                      requiresFile: false,
                    },
                  ], // Initialize with an object containing text and requiresFile properties
                });
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              <PlusIcon className="h-5 w-5 inline-block mr-2" />
              Create Form
            </button>
          </div>

          {isEditing && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setIsSaveModalOpen(true);
              }}
              className="mb-8 bg-white dark:bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Form Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                  id="name"
                  type="text"
                  value={editingForm.name}
                  onChange={(e) =>
                    setEditingForm({
                      ...editingForm,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                  Questions
                </label>
                {editingForm?.questions?.map((question, index) => (
                  <div
                    key={index}
                    className="mb-4 p-4 border border-gray-200 dark:border-gray-700 rounded"
                  >
                    <div className="flex justify-between items-start mb-2">
                      <h4 className="text-gray-700 dark:text-gray-300 font-medium">
                        Question {index + 1}
                      </h4>
                      <button
                        type="button"
                        onClick={() => {
                          const newQuestions = editingForm.questions.filter(
                            (_, i) => i !== index
                          );
                          setEditingForm({
                            ...editingForm,
                            questions: newQuestions,
                          });
                        }}
                        className="text-red-500 hover:text-red-700"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>

                    <div className="mb-2">
                      <textarea
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                        rows="3"
                        value={question.text}
                        onChange={(e) =>
                          handleQuestionChange(index, "text", e.target.value)
                        }
                        placeholder="Enter question text"
                        required
                      />
                    </div>

                    <div className="flex items-center">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-blue-600"
                          checked={question.requiresFile}
                          onChange={(e) =>
                            handleQuestionChange(
                              index,
                              "requiresFile",
                              e.target.checked
                            )
                          }
                        />
                        <span className="ml-2 text-gray-700 dark:text-gray-300">
                          Requires File Attachment
                        </span>
                      </label>
                    </div>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={() =>
                    setEditingForm({
                      ...editingForm,
                      questions: [
                        ...editingForm.questions,
                        { text: "", requiresFile: false },
                      ],
                    })
                  }
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                >
                  <PlusIcon className="h-5 w-5 inline-block mr-2" />
                  Add Question
                </button>
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setIsEditing(false);
                    setEditingForm(null);
                  }}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          )}

          {/* Form Cards */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {forms?.map((form) => (
              <div
                key={form.id}
                className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg"
              >
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                    {form.name}
                  </h3>
                  <div className="mt-2">
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Questions:
                    </h4>
                    <div className="mt-1 space-y-3">
                      {form?.questions?.map((question, index) => (
                        <div
                          key={index}
                          className="border-t border-gray-200 dark:border-gray-700 pt-3"
                        >
                          <p className="text-sm text-gray-900 dark:text-gray-300">
                            {question.text}
                          </p>
                          {question.requiresFile && (
                            <div className="mt-1 flex items-center">
                              <DocumentIcon className="h-4 w-4 text-blue-500 mr-1" />
                              <span className="text-xs text-blue-500">
                                File Required
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-4 flex space-x-3">
                    <button
                      onClick={() => handleEdit(form)}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <PencilIcon className="h-4 w-4 mr-2" />
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                        setFormToDelete(form.id);
                      }}
                      className="hidden inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <TrashIcon className="h-4 w-4 mr-2" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Save Confirmation Modal */}
      {isSaveModalOpen && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                      id="modal-title"
                    >
                      Save Form
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Are you sure you want to save this form?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsSaveModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                      id="modal-title"
                    >
                      Delete Form
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Are you sure you want to delete this form? This action
                        cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    handleDelete(formToDelete);
                    setIsDeleteModalOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setIsDeleteModalOpen(false);
                    setFormToDelete(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminRequestForm;

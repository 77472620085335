import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getQuestions,
  getLocationEvaluations,
  getStudentEvaluations,
  updateEvaluation,
} from "../../services/firebaseService";
import { UserContext } from "../../UserContext";
import { LocationContext } from "../../LocationContext";

const Evaluations = () => {
  const navigate = useNavigate();
  const [evaluation, setEvaluation] = useState(null);
  const [evaluations, setEvaluations] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, permissions } = useContext(UserContext);
  const { selectedLocation } = useContext(LocationContext);

  useEffect(() => {
    const fetchRotations = async () => {
      setLoading(true);
      try {
        let fetchedEvaluations;
        if (user?.id && permissions?.id === "STUDENT") {
          fetchedEvaluations = await getStudentEvaluations(user.id);
        } else if (selectedLocation?.id) {
          fetchedEvaluations = await getLocationEvaluations(
            selectedLocation.id
          );
        }
        setEvaluations(fetchedEvaluations);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchRotations();
  }, [permissions?.id, user?.id, selectedLocation?.id]);

  const handleViewEvaluation = (evaluation) => {
    setEvaluation(evaluation);
    setShowModal(true);
  };

  const handleStartEvaluation = async (evaluation) => {
    try {
      const fetchedQuestions = await getQuestions();
      setQuestions(fetchedQuestions);
      setEvaluation(evaluation);
      setShowModal(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSubmitEvaluation = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(e.target);

      const categories = questions.map((category) => ({
        categoryName: category.category,
        questions: category.questions.map((question) => ({
          questionId: question.id,
          questionText: question.text,
          selectedOption: formData.get(`question_${question.id}`),
        })),
      }));

      const totalQuestions = categories.reduce(
        (total, category) => total + category.questions.length,
        0
      );
      const totalScore = categories.reduce(
        (score, category) =>
          score +
          category.questions.reduce((categoryScore, question) => {
            const value = question.selectedOption;
            return categoryScore + parseInt(value);
          }, 0),
        0
      );
      const evaluationScore = Math.round(
        (totalScore / (totalQuestions * 5)) * 100
      );

      await updateEvaluation(evaluation.id, {
        categories,
        overallFeedback: formData.get("overallFeedback"),
        evaluationScore,
        status: "completed",
        completedAt: new Date(),
      });

      navigate(`/app/evaluations`);
      setShowModal(false);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen dark:text-white dark:bg-gray-800">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center dark:text-red-400">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 dark:bg-gray-900 dark:text-white">
      <h1 className="text-2xl font-bold mb-4">Evaluations</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead className="bg-gray-100 dark:bg-gray-700 text-left">
            <tr>
              <th className="py-2 px-4 border-b dark:border-gray-600">
                Opportunity
              </th>
              <th className="py-2 px-4 border-b dark:border-gray-600">
                Location
              </th>
              <th className="py-2 px-4 border-b dark:border-gray-600">
                Student
              </th>
              <th className="py-2 px-4 border-b dark:border-gray-600">
                Evaluation Date
              </th>
              <th className="py-2 px-4 border-b dark:border-gray-600">
                Status
              </th>
              <th className="py-2 px-4 border-b dark:border-gray-600">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {evaluations?.map((evaluation) => (
              <tr
                key={evaluation.id}
                className="hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <td className="py-2 px-4 border-b dark:border-gray-600">
                  {evaluation.opportunity.name}
                </td>
                <td className="py-2 px-4 border-b dark:border-gray-600">
                  {evaluation.location.name}
                </td>
                <td className="py-2 px-4 border-b dark:border-gray-600">
                  {evaluation.student.name}
                </td>
                <td className="py-2 px-4 border-b dark:border-gray-600">
                  {new Date(
                    evaluation.evaluationDate.seconds * 1000
                  ).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b dark:border-gray-600">
                  <span
                    className={`px-2 py-1 rounded-full text-sm font-semibold ${
                      evaluation.status === "Completed"
                        ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                        : evaluation.status === "In Progress"
                        ? "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200"
                        : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200"
                    }`}
                  >
                    {evaluation.status.toUpperCase()}
                  </span>
                </td>
                <td className="py-2 px-4 border-b dark:border-gray-600">
                  {(permissions.id === "STUDENT" ||
                    permissions.id === "INSTITUTION" ||
                    permissions.id === "SUPER_ADMIN" ||
                    permissions?.viewEvaluation) &&
                  evaluation.status === "completed" ? (
                    <button
                      onClick={() => handleViewEvaluation(evaluation)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded dark:bg-blue-600 dark:hover:bg-blue-800"
                    >
                      View
                    </button>
                  ) : (permissions.id === "SUPER_ADMIN" || permissions?.startEvaluation) &&
                    evaluation.status !== "completed" ? (
                    <button
                      onClick={() => handleStartEvaluation(evaluation)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded dark:bg-blue-600 dark:hover:bg-blue-800"
                    >
                      Start Evaluation
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full dark:bg-gray-900 dark:bg-opacity-50"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white dark:bg-gray-800 dark:border-gray-700 max-h-[80vh] overflow-y-auto">
            <div className="mt-3 text-center">
              {evaluation && evaluation.status === "completed" ? (
                <div>
                  <h2 className="text-xl font-bold mb-4">Evaluation Details</h2>
                  <div className="text-left">
                    <p>
                      <strong>Student:</strong> {evaluation.student.name}
                    </p>
                    <p>
                      <strong>Evaluator:</strong> {evaluation?.evaluator?.name}
                    </p>
                    <p>
                      <strong>Evaluation Date:</strong>{" "}
                      {new Date(
                        evaluation.evaluationDate.seconds * 1000
                      ).toLocaleDateString()}
                    </p>
                    <p>
                      <strong>Overall Score:</strong>{" "}
                      {evaluation.evaluationScore}
                    </p>
                    <h3 className="text-lg font-semibold mt-4 mb-2">
                      Categories
                    </h3>
                    {evaluation.categories.map((category, index) => (
                      <div
                        key={index}
                        className="mb-6 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg"
                      >
                        <h4 className="font-semibold text-lg mb-3">
                          {category.categoryName}
                        </h4>
                        {category.questions.map((question, qIndex) => (
                          <div
                            key={qIndex}
                            className="mb-3 pl-4 border-l-4 border-blue-500"
                          >
                            <p className="font-medium text-gray-700 dark:text-gray-300">
                              {question.questionText}
                            </p>
                            <p className="mt-1 font-semibold text-blue-600 dark:text-blue-400">
                              Answer: {question.selectedOption}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))}
                    <h3 className="text-lg font-semibold mt-4 mb-2">
                      Overall Feedback
                    </h3>
                    <p>{evaluation.overallFeedback}</p>
                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      onClick={() => setShowModal(false)}
                      className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-white"
                    >
                      Close
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="text-xl font-bold mb-4">New Evaluation</h2>
                  <form onSubmit={handleSubmitEvaluation} className="mt-8">
                    {questions.map((category) => (
                      <div
                        key={category.category}
                        className="mb-8 bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md"
                      >
                        <h3 className="text-xl font-semibold mb-4 text-blue-600 dark:text-blue-400">
                          {category.category}
                        </h3>
                        {category.questions.map((question, qIndex) => (
                          <div
                            key={question.id}
                            className="mb-6 border-b border-gray-300 dark:border-gray-600 pb-4 last:border-b-0"
                          >
                            <p className="mb-3 text-lg font-medium text-gray-800 dark:text-gray-200">{`${
                              qIndex + 1
                            }. ${question.text}`}</p>
                            <div className="grid grid-cols-1 md:grid-cols-1 gap-3">
                              {question.options.map((option, index) => (
                                <label
                                  key={index}
                                  className="flex items-center p-3 bg-white dark:bg-gray-600 rounded-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-500 transition-colors"
                                >
                                  <input
                                    type="radio"
                                    name={`question_${question.id}`}
                                    value={option}
                                    className="form-radio h-5 w-5 text-blue-600 dark:text-blue-400"
                                    required
                                  />
                                  <span className="ml-3 text-gray-700 dark:text-gray-300">
                                    {option}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                    <div className="mb-6">
                      <label className="block mb-2 font-semibold">
                        Overall Feedback
                      </label>
                      <textarea
                        name="overallFeedback"
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        rows="4"
                      ></textarea>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded mr-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-white"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded dark:bg-blue-600 dark:hover:bg-blue-800"
                      >
                        Submit Evaluation
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Evaluations;

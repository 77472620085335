import { useState, useEffect } from "react";
import { getFormSubmissionByRotationId } from "../../services/firebaseService";

const FormSubmissionDetails = ({ rotationId }) => {
  const [submission, setSubmission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        const data = await getFormSubmissionByRotationId(rotationId);
        setSubmission(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (rotationId) {
      fetchSubmission();
    }
  }, [rotationId]);

  if (loading) {
    return (
      <div className="flex justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <p className="text-red-500 dark:text-red-400">Error: {error}</p>
      </div>
    );
  }

  if (!submission) {
    return (
      <div className="p-4">
        <p className="dark:text-gray-300">No submission found</p>
      </div>
    );
  }

  return (
    <div className="border rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div
        className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
        onClick={() =>
          document
            .getElementById("submission-details")
            .classList.toggle("hidden")
        }
      >
        <h2 className="text-xl font-semibold dark:text-white">
          Request Form Details - {submission.student?.name}
        </h2>
        <svg
          className="w-6 h-6 dark:text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>

      <div
        id="submission-details"
        className="p-4 border-t dark:border-gray-700"
      >
        <div>
          <p className="text-lg mb-2 dark:text-white">
            Opportunity: {submission.opportunity?.name}
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
            Submitted by: {submission.student?.name} 
            {submission.student?.email}
          </p>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4 dark:text-white">
              Answers:
            </h3>
            <div className="mb-4">
              <p className="font-medium mb-1 dark:text-white">First Name:</p>
              <p className="text-gray-700 dark:text-gray-300">
                {submission.student?.name.split(" ")[0]}
              </p>
            </div>
            <div className="mb-4">
              <p className="font-medium mb-1 dark:text-white">Middle Name:</p>
              <p className="text-gray-700 dark:text-gray-300">
                {submission.student?.name.split(" ")[1]}
              </p>
            </div>
            <div className="mb-4">
              <p className="font-medium mb-1 dark:text-white">Family Name:</p>
              <p className="text-gray-700 dark:text-gray-300">
                {submission.student?.name.split(" ")[2]}
              </p>
            </div>
            <div className="mb-4">
              <p className="font-medium mb-1 dark:text-white">Student ID:</p>
              <p className="text-gray-700 dark:text-gray-300">
                {submission.student?.studentId}
              </p>
            </div>
            <div className="mb-4">
              <p className="font-medium mb-1 dark:text-white">Phone Number:</p>
              <p className="text-gray-700 dark:text-gray-300">
                {submission.student?.phoneNumber}
              </p>
            </div>
            {console.log(submission)}
            {submission.answers.map((answer, index) => (
              <div key={index} className="mb-4">
                <p className="font-medium mb-1 dark:text-white">
                  {answer.question}
                </p>
                {answer.isFile ? (
                  <a
                    href={answer.answer}
                    target="_blank"
                    rel="noopener"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 hover:underline"
                  >
                    View Uploaded File
                  </a>
                ) : (
                  <p className="text-gray-700 dark:text-gray-300">
                    {answer.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSubmissionDetails;

import { useState, useEffect, useContext } from "react";
import Map from "../Map/Map";
import { useRotations } from "../../hooks/useRotations";
import { useParams, useNavigate } from "react-router-dom";
import RequestForm from "../RequestForm/RequestForm";
import { UserContext } from "../../UserContext";
import {
  createCard,
  getLocationSectionsByLocationIdAndOrder,
  getOpportunities,
  createRotationRequest,
  checkRotationExists,
  getUserRegistrations
} from "../../services/firebaseService";

import uni1 from "../../assets/images/uni1.png";
import uni2 from "../../assets/images/uni2.png";
import placeholder from "../../assets/images/placeholder.png";
const Search = () => {
  const { user, permissions } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const DateDisplay = ({ startDate, endDate }) => {
    const dateClass = user ? 
      "text-sm text-gray-600 dark:text-gray-300" : 
      "text-sm text-gray-600 dark:text-gray-300 blur-sm select-none";
    
    return (
      <>
        <p className={dateClass}>
          {user ? startDate : "XX/XX/XXXX"}
        </p>
        {endDate && (
          <p className={dateClass}>
            {user ? endDate : "XX/XX/XXXX"}
          </p>
        )}
      </>
    );
  };

  const OpportunityPopup = ({ opportunity }) => (
    <div className="flex">
      <img
        src={opportunity.location.image}
        alt="University Logo"
        className="mr-4"
        style={{ width: "90px", height: "90px" }}
      />
      <div>
        <h1 className="text-2xl font-bold">{opportunity.location.name}</h1>
        <p>{opportunity.name}</p>
        <p>{opportunity.description}</p>
        <p>{opportunity.startDate}</p>
        {opportunity.endDate && <p>{opportunity.endDate}</p>}
        <button
          className="mt-2 px-4 py-2 rounded"
          style={{ backgroundColor: "#0070f3", color: "white" }}
          onClick={() => {
            setSelectedOpportunity(opportunity);
            setIsModalOpen(true);
          }}
        >
          View Details
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    console.log("user:", user);
    const fetchOpportunities = async () => {
      setLoading(true);
      try {
        // Get all opportunities in one call
        const allOpportunities = await getOpportunities();
        
        if (user) {
          // Get all user registrations in one call
          const userRegistrations = await getUserRegistrations(user.id);
          // Filter locally using the registration data 
          const filteredOpportunities = allOpportunities.filter(opp => 
            !userRegistrations.includes(opp.id)
          );
          setOpportunities(filteredOpportunities);
        } else {
          setOpportunities(allOpportunities);
        }

      } catch (err) {
        console.error('Error fetching opportunities:', err);
        setError('Failed to load opportunities');
      } finally {
        setLoading(false);
      }
    };
    
    fetchOpportunities();
  }, [user?.id]);

  const handleApply = () => {
    setIsRequestFormOpen(true);
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (submissionId) => {
    setIsRequestFormOpen(false);
    setSuccess(true);
  };

  const filteredOpportunities = opportunities.filter(opportunity => {
    const searchString = searchTerm.toLowerCase();
    return (
      opportunity.location.name.toLowerCase().includes(searchString) ||
      opportunity.name.toLowerCase().includes(searchString) ||
      opportunity.description.toLowerCase().includes(searchString)
    );
  });

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
        </div>
      )}
      {error && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Error
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={() => setError(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row h-screen mt-10">        
        {opportunities.map((opportunity) => {
          // console.log("main:", opportunity.id);
          // console.log("sub:", opportunity.location.location);
        })}
        <div className="w-full lg:w-[60%]">
          <Map
            initialZoom={10}
            markers={filteredOpportunities
              .filter(
                (opportunity) =>
                  opportunity.location.location &&
                  opportunity.location.location._lat !== undefined &&
                  opportunity.location.location._long !== undefined 
                  && opportunity?.seats > 0
              )
              .map((opportunity) => {

                return {
                  position: [
                    opportunity.location.location._lat,
                    opportunity.location.location._long,
                  ],
                  popupContent: <OpportunityPopup opportunity={opportunity} />,
                };
              })}
            mapStyle={{
              zIndex: 0,
              height: "80vh",
              width: "100%",
              lg: { height: "90vh", width: "100%" },
            }}
          />
        </div>

        <div className="w-full lg:w-[40%] h-full overflow-y-auto bg-white dark:bg-gray-800">
          <div className="p-4">
            <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
              Locations
            </h2>
            <div className="w-full px-4 mb-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
              <input
                type="text"
                placeholder="Search opportunities..."
                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {filteredOpportunities.filter(opportunity => opportunity?.seats > 0).length === 0 ? (
              <div className="p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
                <p className="text-gray-800 dark:text-white text-center">Currently there are no opportunities available</p>
              </div>
            ) : (
              filteredOpportunities
                .filter(opportunity => opportunity?.seats > 0)
                .map((opportunity) => {
                  return (
                    <div
                      key={opportunity.id}
                      className="mb-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow flex cursor-pointer"
                      onClick={() => {
                        setSelectedOpportunity(opportunity);
                        setIsModalOpen(true);
                      }}
                    >
                      <img
                        src={opportunity.location.image}
                        alt={opportunity.location.name}
                        className="w-20 h-20 mr-2 object-cover rounded-lg"
                      />
                      <div className="w-2/3">
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                          {opportunity.location.name}
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          {opportunity.name}
                        </p>
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          {opportunity.description}
                        </p>
                        <DateDisplay 
                          startDate={opportunity.startDate}
                          endDate={opportunity.endDate}
                        />
                      </div>
                    </div>
                  )
                })
            )}
          </div>
        </div>
      </div>

      {selectedOpportunity && user ? (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center ${
            isModalOpen ? "block" : "hidden"
          }`}
        >
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
            <div className="p-4">
              <img
                src={selectedOpportunity.location.image}
                alt="University Logo"
                className="mr-4 w-24 h-24 object-cover rounded-lg"
              />
              <div>
                <h1 className="text-2xl font-bold">
                  {selectedOpportunity.location.name}
                </h1>
                <p>{selectedOpportunity.name}</p>
                <p>{selectedOpportunity.description}</p>
                <p>{selectedOpportunity.startDate}</p>
                {selectedOpportunity.endDate && (
                  <p>{selectedOpportunity.endDate}</p>
                )}
              </div>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              {permissions?.id === "STUDENT" && (
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setIsConfirmationModalOpen(true);
                  }}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center ${
            isModalOpen ? "block" : "hidden"
          }`}
        >
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
            <div className="p-4">
              <h1 className="text-2xl font-bold text-red-600 dark:text-red-400">Login Required</h1>
              <p className="mt-2 text-gray-600 dark:text-gray-300">You must be logged in to apply for this opportunity.</p>
              <div className="mt-4 flex space-x-4">
                <button
                  onClick={() => window.location.href = '/app/login'}
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                >
                  Login
                </button>
                <button
                  onClick={() => window.location.href = '/app/signup'}
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isConfirmationModalOpen && permissions?.id === "STUDENT" && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  // Handle confirm action here
                  handleApply();
                  setIsConfirmationModalOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
            <div className="p-4">
              <h1 className="text-2xl font-bold">Are you sure?</h1>
              <p>
                Do you want to apply to this opportunity? This process will be
                sent to the location for approval.
              </p>
            </div>
          </div>
        </div>
      )}
      {success && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="p-4">
              <h1 className="text-2xl font-bold">Success!</h1>
              <p>Your application has been successfully submitted.</p>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setSuccess(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isRequestFormOpen &&
        selectedOpportunity &&
        permissions?.id === "STUDENT" && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative bg-white dark:bg-gray-800 rounded-lg p-6 max-w-2xl w-full m-4">
              <RequestForm
                formId={selectedOpportunity.form?.id}
                opportunityId={selectedOpportunity.id}
                studentId={user.id}
                onSubmit={handleFormSubmit}
                onCancel={() => setIsRequestFormOpen(false)}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default Search;

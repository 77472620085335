import React, { useContext } from "react";
import {
  AcademicCapIcon,
  MapPinIcon,
  CalendarIcon,
  UserGroupIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline";
import { useNavigate, Navigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const AdminPanel = () => {
  const navigate = useNavigate();

  const { user, permissions } = useContext(UserContext);

  const items = [
    ...(permissions?.ALL ? [
      { name: "Training Centers", icon: AcademicCapIcon, link: "universities" },
    ] : []),
    { name: "Locations", icon: MapPinIcon, link: "locations" },
    { name: "Request Form", icon: BriefcaseIcon, link: "forms" },
    { name: "Opportunities", icon: BriefcaseIcon, link: "opportunities" },
    { name: "Life Cycle Setup", icon: BriefcaseIcon, link: "lifecycle" },
    { name: "Tasks Setup", icon: BriefcaseIcon, link: "tasks" },
    // { name: "Rotations", icon: CalendarIcon, link: "rotations" },
    { name: "Users", icon: UserGroupIcon, link: "users" },
    ...(permissions?.ALL ? [
      {
        name: "Manage Permissions",
        icon: UserGroupIcon,
        link: "manage-permissions",
      },
      { name: "Contact List", icon: UserGroupIcon, link: "contact-list" },
    ] : []),
  ];

  // if (!permissions?.adminPanel && !permissions?.ALL) {
  //   return <Navigate to="/" />;
  // }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          Admin Panel
        </h1>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {items.map((item) => (
            <div
              key={item.name}
              className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg cursor-pointer hover:shadow-md transition duration-300 ease-in-out"
              onClick={() => {
                navigate(`/app/admin/7x4b2k-h9z83p/${item.link}`);
              }}
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <item.icon
                      className="h-6 w-6 text-gray-400 dark:text-gray-300"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                        {item.name}
                      </dt>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-5 py-3">
                <div className="text-sm">
                  <span className="font-medium text-indigo-600 dark:text-indigo-400">
                    Click to view all
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

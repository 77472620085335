// src/components/Rotations.js
import { useState, useEffect, useContext } from "react";
import Sections from "../Sections/Sections";
import RotationsTable from "./RotationsTable";
import { useParams, useNavigate } from "react-router-dom";
import { LocationContext } from "../../LocationContext";
const Rotations = () => {
  const { lifecycles, selectedLocation } = useContext(LocationContext);
  const { baseId } = useParams();
  const navigate = useNavigate();
  const [rotations, setRotations] = useState([]);
  const [filteredRotations, setFilteredRotations] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFilteredRotations(
      lifecycles?.[selectedLocation?.id]?.stages.flatMap((stage) => stage.cards)
    );
  }, [lifecycles, selectedLocation]);

  const handleSearch = (searchTerm) => {
    const filtered = rotations.filter((rotation) =>
      rotation.opps.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRotations(filtered);
  };

  return (
    <>
    {console.log(lifecycles)}
      {error && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Error
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={() => setError(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Rotations</h1>
        <Sections />
        <input
          type="text"
          placeholder="Find rotations by name"
          className="w-full p-2 mb-4 border border-gray-300 rounded bg-gray-800 text-white dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <RotationsTable
          filteredRotations={filteredRotations}
          navigate={navigate}
          baseId={baseId}
        />
        {/* <div className="relative overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Rotation
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Site
                </th>
                <th scope="col" className="px-6 py-3">
                  Student
                </th>
                <th scope="col" className="px-6 py-3">
                  Start
                </th>
                <th scope="col" className="px-6 py-3">
                  End
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRotations.map((rotation, i) => (
                <tr
                  key={i}
                  onClick={() =>
                    navigate(
                      `/university/${baseId}/${rotation.opps.location.id}/card/${rotation.id}`
                    )
                  }
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {rotation.opps.name}
                  </th>
                  <td className="px-6 py-4">
                    <span
                      className={`text-sm font-medium mr-2 px-2.5 py-0.5 rounded ${getTypeBadgeColor(
                        "Core"
                      )}`}
                    >
                      Core
                    </span>
                  </td>
                  <td className="px-6 py-4">{rotation.location.name}</td>
                  <td className="px-6 py-4">{rotation.student.name}</td>
                  <td className="px-6 py-4">
                    {rotation.opps.startDate
                      ? new Date(rotation.opps.startDate).toLocaleDateString(
                          "en-GB"
                        )
                      : "N/A"}
                  </td>
                  <td className="px-6 py-4">
                    {rotation.opps.endDate
                      ? new Date(rotation.opps.endDate).toLocaleDateString(
                          "en-GB"
                        )
                      : "N/A"}
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`text-sm font-medium mr-2 px-2.5 py-0.5 rounded ${getStatusBadgeColor(
                        rotation.status
                      )}`}
                    >
                      {rotation.status === 1
                        ? "Pending"
                        : rotation.status === 2 ||
                          rotation.status === 3 ||
                          rotation.status === 4
                        ? "Active"
                        : rotation.status === 5
                        ? "Completed"
                        : rotation.status === 6
                        ? "Closed"
                        : "N/A"}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav
          className="flex items-center justify-between pt-4 flex-wrap"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            Showing{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {indexOfFirstRotation + 1}-{indexOfLastRotation}
            </span>{" "}
            of{" "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {rotations.length}
            </span>
          </span>
          <ul className="inline-flex -space-x-px text-sm h-8">
            <li>
              <a
                href="#"
                className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </a>
            </li>
            {Array.from({
              length: Math.ceil(rotations.length / rotationsPerPage),
            }).map((_, index) => (
              <li key={index}>
                <a
                  href="#"
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage === index + 1
                      ? "text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </a>
              </li>
            ))}
            <li>
              <a
                href="#"
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(rotations.length / rotationsPerPage)
                }
              >
                Next
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
    </>
  );
};

export default Rotations;

import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getRequestsByLocation,
  getRequestsByBase,
} from "../../services/firebaseService";
import { LocationContext } from "../../LocationContext";

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { baseId, locationId } = useParams();
  const navigate = useNavigate();
  const { locations, selectedLocation } = useContext(LocationContext);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        if (selectedLocation?.id) {
          const requests = await getRequestsByLocation(selectedLocation.id, 1);
          setRequests(requests);
        } else if (locations && locations.length > 0) {
          const firstLocation = locations[0];
          const requests = await getRequestsByLocation(firstLocation.id, 1);
          setRequests(requests);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchBaseRequests = async () => {
      try {
        const requests = await getRequestsByBase(baseId, 1);
        // console.log("requests", requests);
        setRequests(requests);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchRequests();
  }, [locations, selectedLocation]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Requests</h1>
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
        </div>
      )}
      {error && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Error
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={() => setError(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {requests.map((request) => (
          <div
            key={request.id}
            className="mb-4 p-2 bg-white dark:bg-gray-800 rounded-lg shadow-md cursor-pointer"
            // style={{
            //   borderColor: request.section.color,
            //   borderRight: `4px solid ${request.section.color}`,
            // }}
            onClick={() => {
              navigate(
                `/university/${baseId}/${request.opps.location.id}/card/${request.id}`
              );
            }}
          >
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {new Date(
                request.opps.startDate.seconds * 1000 +
                  request.opps.startDate.nanoseconds / 1000000
              ).toLocaleDateString()}{" "}
              -{" "}
              {new Date(
                request.opps.endDate.seconds * 1000 +
                  request.opps.endDate.nanoseconds / 1000000
              ).toLocaleDateString()}
            </p>
            {/* <div className="flex items-center">
              <div>
                <p className="text-xs text-gray-600 dark:text-gray-300">
                  {request.opps.name}
                </p>
              </div>
            </div> */}
            <hr className="border-gray-300 dark:border-gray-700 my-1" />
            <div className="flex items-center">
              <img
                src={request.location.image}
                alt={request.location.name}
                className="w-16 h-16 object-cover mr-2"
              />
              <div>
                <p className="text-gray-700 dark:text-gray-300">
                  {request.location.name}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  {request.opps.name}
                </p>
              </div>
            </div>
            <hr className="border-gray-300 dark:border-gray-700 my-1" />
            <div className="flex items-center mt-2">
              <div className="w-10 h-10 rounded-full mr-2 flex items-center justify-center bg-gray-500 dark:bg-gray-700 text-white font-bold">
                {request.student
                  ? request.student.name
                      .split(" ")
                      .map((n) => n[0])
                      .join("")
                      .substring(0, 2)
                      .toUpperCase()
                  : ""}
              </div>
              <div>
                <p className="text-sm font-medium dark:text-white">
                  {request.student.name}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  {request.student.role}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Requests;

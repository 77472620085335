import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllLifecycles,
  getLifecyclesByBaseId,
  createLifecycle,
  updateLifecycle,
  deleteLifecycle,
  getAllLocations,
  getLocationsByBaseId,
  getAllTasks,
  getTasksByBaseId,
} from "../services/firebaseService";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  CheckIcon,
  ExclamationCircleIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { LocationContext } from "../LocationContext";
import { UserContext } from "../UserContext";

const getSelectedTaskNames = (selectedTaskIds, allTasks) => {
  return selectedTaskIds
    .map((id) => allTasks?.find((task) => task.id === id)?.name)
    .filter(Boolean)
    .join(", ");
};

const AdminLifecycle = () => {
  const navigate = useNavigate();
  const [lifecycles, setLifecycles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingLifecycle, setEditingLifecycle] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [lifecycleToDelete, setLifecycleToDelete] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { permissions } = useContext(UserContext);
  const { baseId } = useContext(LocationContext);
  useEffect(() => {
    fetchLifecycles();
    fetchLocations();
    fetchTasks();
  }, [baseId]);

  const fetchLifecycles = async () => {
    setIsLoading(true);
    try {
      const hasAllPermission = permissions?.ALL;
      let lifecyclesSnapshot;
      
      if (hasAllPermission) {
        lifecyclesSnapshot = await getAllLifecycles();
      } else {
        if (baseId) {
          console.log("Fetching lifecycles for baseId: ", baseId);
          lifecyclesSnapshot = await getLifecyclesByBaseId(baseId);
        }
      }
      setLifecycles(lifecyclesSnapshot);
    } catch (error) {
      setErrorMessage("Error fetching lifecycles: " + error.message);
    }
    setIsLoading(false);
  };

  const fetchLocations = async () => {
    try {
      const hasAllPermission = permissions?.ALL;
      let locationsSnapshot;
      
      if (hasAllPermission) {
        locationsSnapshot = await getAllLocations();
      } else {
        if (baseId) {
          locationsSnapshot = await getLocationsByBaseId(baseId);
        }
      }
      setLocations(locationsSnapshot);
    } catch (error) {
      setErrorMessage("Error fetching locations: " + error.message);
    }
  };

  const fetchTasks = async () => {
    try {
      const hasAllPermission = permissions?.ALL;
      let tasksData;
      
      if (hasAllPermission) {
        tasksData = await getAllTasks();
      } else {
        if (baseId) {
          tasksData = await getTasksByBaseId(baseId);
        }
      }
      setTasks(tasksData);
    } catch (error) {
      setErrorMessage("Error fetching tasks: " + error.message);
    }
  };

  const handleEdit = (lifecycle) => {
    const formattedLifecycle = {
      ...lifecycle,
      stages: lifecycle?.stages?.map((stage) => ({
        ...stage,
        tasks:
          stage?.tasks?.map((task) =>
            typeof task === "object" ? task.id : task
          ) || [],
      })),
    };

    setIsEditing(true);
    setEditingLifecycle(formattedLifecycle);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDelete = async (id) => {
    try {
      await deleteLifecycle(id);
      await fetchLifecycles();
      setIsDeleteModalOpen(false);
      setLifecycleToDelete(null);
    } catch (error) {
      setErrorMessage("Error deleting lifecycle: " + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      editingLifecycle.baseId = baseId;
      if (editingLifecycle.id) {
        // console.log("updating lifecycle", editingLifecycle);
        await updateLifecycle(editingLifecycle.id, editingLifecycle);
      } else {
        await createLifecycle(editingLifecycle);
      }
      setIsEditing(false);
      setEditingLifecycle(null);
      fetchLifecycles();
    } catch (error) {
      setErrorMessage("Error saving lifecycle: " + error.message);
    }
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <>
      <div className="mb-4">
        <button
          onClick={() => window.history.back()}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          <ArrowLeftIcon className="h-5 w-5 inline-block mr-2" />
          Back
        </button>
      </div>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Lifecycle Management
          </h1>
          <div className="mb-4">
            {(permissions?.ALL || !lifecycles?.length) && (
              <button
                onClick={() => {
                  setIsEditing(true);
                  setEditingLifecycle({
                    name: "",
                    locationId: "",
                    stages: [],
                  });
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <PlusIcon className="h-5 w-5 inline-block mr-2" />
                Create Lifecycle
              </button>
            )}
          </div>

          {isEditing && (
            <form
              onSubmit={handleSubmit}
              className="mb-8 bg-white dark:bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                  id="name"
                  type="text"
                  value={editingLifecycle.name}
                  onChange={(e) =>
                    setEditingLifecycle({
                      ...editingLifecycle,
                      name: e.target.value,
                    })
                  }
                  placeholder="Lifecycle name"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="location"
                >
                  Location
                </label>
                <select
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                  id="location"
                  value={editingLifecycle.locationId}
                  onChange={(e) =>
                    setEditingLifecycle({
                      ...editingLifecycle,
                      locationId: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select a location</option>
                  {locations?.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                  Stages
                </label>
                {editingLifecycle?.stages?.map((stage, index) => (
                  <div
                    key={index}
                    className="mb-4 p-4 border rounded-lg bg-gray-50 dark:bg-gray-700"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {/* Stage Name */}
                      <div>
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                          Stage Name
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                          type="text"
                          value={stage.name}
                          onChange={(e) => {
                            const newStages = [...editingLifecycle.stages];
                            newStages[index] = {
                              ...newStages[index],
                              name: e.target.value,
                            };
                            setEditingLifecycle({
                              ...editingLifecycle,
                              stages: newStages,
                            });
                          }}
                          placeholder="Stage name"
                        />
                      </div>

                      {/* Stage Color */}
                      <div>
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                          Color
                        </label>
                        <div className="relative">
                          <input
                            className="shadow appearance-none border rounded w-32 h-12 py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 cursor-pointer"
                            type="color"
                            value={stage.color || "#000000"}
                            onFocus={(e) => {
                              e.target.nextElementSibling.classList.remove(
                                "hidden"
                              );
                            }}
                            onBlur={(e) => {
                              setTimeout(() => {
                                e.target.nextElementSibling.classList.add(
                                  "hidden"
                                );
                              }, 200);
                            }}
                            onChange={(e) => {
                              const newStages = [...editingLifecycle.stages];
                              newStages[index] = {
                                ...newStages[index],
                                color: e.target.value,
                              };
                              setEditingLifecycle({
                                ...editingLifecycle,
                                stages: newStages,
                              });
                            }}
                          />
                          <div className="hidden absolute sm:left-[250px] sm:top-0 left-0 top-[300px] w-64 p-4 bg-white dark:bg-gray-800 shadow-lg rounded-lg z-10 border border-gray-200 dark:border-gray-700">
                            <div className="space-y-2">
                              <div className="bg-white p-2 rounded">
                                <span
                                  style={{ color: stage.color || "#000000" }}
                                >
                                  Text on white background
                                </span>
                              </div>
                              <div className="bg-gray-100 p-2 rounded">
                                <span
                                  style={{ color: stage.color || "#000000" }}
                                >
                                  Text on light gray background
                                </span>
                              </div>
                              <div className="bg-gray-700 p-2 rounded">
                                <span
                                  style={{ color: stage.color || "#000000" }}
                                >
                                  Text on dark gray background
                                </span>
                              </div>
                              <div className="bg-gray-900 p-2 rounded">
                                <span
                                  style={{ color: stage.color || "#000000" }}
                                >
                                  Text on black background
                                </span>
                              </div>
                              <div className="border border-gray-200 dark:border-gray-600 p-2 rounded">
                                <span
                                  style={{ color: stage.color || "#000000" }}
                                >
                                  Text with current theme background
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Stage Order */}
                      <div>
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                          Order
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                          type="number"
                          value={stage.order}
                          onChange={(e) => {
                            const newStages = [...editingLifecycle.stages];
                            newStages[index] = {
                              ...newStages[index],
                              order: parseInt(e.target.value),
                            };
                            setEditingLifecycle({
                              ...editingLifecycle,
                              stages: newStages,
                            });
                          }}
                          placeholder="Order"
                          min="1"
                        />
                      </div>

                      {/* Evaluation Required */}
                      <div>
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                          Evaluation Required
                        </label>
                        <input
                          type="checkbox"
                          checked={stage.evaluation || false}
                          onChange={(e) => {
                            const newStages = [...editingLifecycle.stages];
                            newStages[index] = {
                              ...newStages[index],
                              evaluation: e.target.checked,
                            };
                            setEditingLifecycle({
                              ...editingLifecycle,
                              stages: newStages,
                            });
                          }}
                          className="mr-2"
                        />
                      </div>

                      {/* Tasks Selection */}
                      <div className="md:col-span-2">
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                          Tasks
                        </label>
                        <div className="relative max-h-48 overflow-y-auto border rounded p-2 bg-white dark:bg-gray-700">
                          {tasks?.length > 0 ? (
                            tasks.map((task) => (
                              <div
                                key={task.id}
                                className="flex items-center mb-2"
                              >
                                <input
                                  type="checkbox"
                                  id={`task-${task.id}-${index}`}
                                  value={task.id}
                                  checked={stage.tasks?.includes(task.id)}
                                  onChange={(e) => {
                                    const newStages = [
                                      ...editingLifecycle.stages,
                                    ];
                                    const taskId = e.target.value;
                                    if (e.target.checked) {
                                      newStages[index] = {
                                        ...newStages[index],
                                        tasks: [
                                          ...(newStages[index].tasks || []),
                                          taskId,
                                        ],
                                      };
                                    } else {
                                      newStages[index] = {
                                        ...newStages[index],
                                        tasks: newStages[index].tasks.filter(
                                          (id) => id !== taskId
                                        ),
                                      };
                                    }
                                    setEditingLifecycle({
                                      ...editingLifecycle,
                                      stages: newStages,
                                    });
                                  }}
                                  className="mr-2"
                                />
                                <label
                                  htmlFor={`task-${task.id}-${index}`}
                                  className="text-gray-700 dark:text-gray-300 cursor-pointer"
                                >
                                  {task.name}
                                </label>
                              </div>
                            ))
                          ) : (
                            <div className="text-gray-500 dark:text-gray-400 text-center py-2">
                              No tasks available. Please create tasks first to add them here.
                            </div>
                          )}
                        </div>

                        {/* Selected Tasks Preview */}
                        {stage.tasks && stage.tasks.length > 0 && (
                          <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                            Selected: {getSelectedTaskNames(stage.tasks, tasks)}
                          </div>
                        )}
                      </div>

                      {/* Delete Stage Button */}
                      <div className="md:col-span-2 flex justify-end">
                        <button
                          type="button"
                          onClick={() => {
                            const newStages = editingLifecycle.stages.filter(
                              (_, i) => i !== index
                            );
                            setEditingLifecycle({
                              ...editingLifecycle,
                              stages: newStages,
                            });
                          }}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                        >
                          <TrashIcon className="h-5 w-5 mr-2" />
                          Delete Stage
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    setEditingLifecycle({
                      ...editingLifecycle,
                      stages: [
                        ...editingLifecycle.stages,
                        {
                          name: "",
                          order: editingLifecycle.stages.length + 1,
                          tasks: [],
                          evaluation: false,
                          color: "#000000",
                        },
                      ],
                    })
                  }
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                >
                  <PlusIcon className="h-5 w-5 inline-block mr-2" />
                  Add Stage
                </button>
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setIsEditing(false);
                    setEditingLifecycle(null);
                  }}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          )}

          {/* Lifecycle Cards */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {lifecycles?.map((lifecycle) => (
              <div
                key={lifecycle.id}
                className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg"
              >
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                    {lifecycle.name}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
                    Location:{" "}
                    {
                      locations.find((loc) => loc.id === lifecycle.locationId)
                        ?.name
                    }
                  </p>
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Stages:
                    </h4>
                    <div className="mt-2 space-y-3">
                      {lifecycle?.stages
                        ?.sort((a, b) => a.order - b.order)
                        ?.map((stage, index) => (
                          <div
                            key={index}
                            className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg"
                            style={{
                              borderLeft: `4px solid ${
                                stage.color || "#000000"
                              }`,
                            }}
                          >
                            <div className="font-medium text-gray-700 dark:text-gray-300">
                              {stage.name} (Order: {stage.order})
                            </div>
                            {stage.evaluation && (
                              <div className="text-xs text-gray-400 dark:text-gray-500">
                                Evaluation Required
                              </div>
                            )}
                            {stage.tasks && stage.tasks.length > 0 && (
                              <div className="mt-2">
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                  Tasks:
                                </div>
                                <ul className="mt-1 pl-5 list-disc text-sm text-gray-500 dark:text-gray-400">
                                  {stage?.tasks?.map((task, taskIndex) => (
                                    <li key={taskIndex} className="text-sm">
                                      {task.name}
                                      {task.questions && (
                                        <ul className="pl-4 mt-1 list-circle">
                                          {task?.questions?.map(
                                            (question, qIndex) => (
                                              <li
                                                key={qIndex}
                                                className="text-xs text-gray-400 dark:text-gray-500"
                                              >
                                                {question.text}
                                                {question.requiresFile && (
                                                  <span className="ml-1 text-blue-500">
                                                    (File Required)
                                                  </span>
                                                )}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Existing edit/delete buttons */}
                  <div className="mt-4 flex space-x-3">
                    <button
                      onClick={() => handleEdit(lifecycle)}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <PencilIcon className="h-4 w-4 mr-2" />
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                        setLifecycleToDelete(lifecycle.id);
                      }}
                      className="hidden inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <TrashIcon className="h-4 w-4 mr-2" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                      id="modal-title"
                    >
                      Delete Lifecycle
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Are you sure you want to delete this lifecycle? This
                        action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    handleDelete(lifecycleToDelete);
                    setIsDeleteModalOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setIsDeleteModalOpen(false);
                    setLifecycleToDelete(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminLifecycle;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllLocations,
  createLocation2,
  updateLocation2,
  deleteLocation2,
  getAllBases,
  getLocationsByBaseId,
} from "../services/firebaseService";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  CheckIcon,
  ExclamationCircleIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import ImageUploader from "../components/ImageUploader";
import { LocationContext } from "../LocationContext";
import { UserContext } from "../UserContext";

const AdminLocation = () => {
  const navigate = useNavigate();
  const { baseId } = useContext(LocationContext);
  const { permissions } = useContext(UserContext);
  const [locations, setLocations] = useState([]);
  const [bases, setBases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingLocation, setEditingLocation] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [mapUrl, setMapUrl] = useState("");

  useEffect(() => {
    fetchLocations();
    fetchBases();
  }, [baseId]);

  const fetchLocations = async () => {
    setIsLoading(true);
    try {
      // Check if user has 'all' permission
      const hasAllPermission = permissions?.ALL; // Replace with actual permission check
      
      let locationsSnapshot;
      if (hasAllPermission) {
        locationsSnapshot = await getAllLocations();
      } else {
        // Fetch locations only for selected base
        if (baseId) {
          locationsSnapshot = await getLocationsByBaseId(baseId);
        }
      }
      setLocations(locationsSnapshot);
    } catch (error) {
      setErrorMessage("Error fetching locations: " + error.message);
    }
    setIsLoading(false);
  };

  const fetchBases = async () => {
    try {
      const basesSnapshot = await getAllBases();
      setBases(basesSnapshot);
    } catch (error) {
      setErrorMessage("Error fetching bases: " + error.message);
    }
  };

  const handleEdit = (location) => {
    setIsEditing(true);
    setEditingLocation(location);
    setMapUrl(location.mapUrl || ""); // Set mapUrl from location data
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDelete = async (id) => {
    try {
      await deleteLocation2(id);
      fetchLocations();
    } catch (error) {
      setErrorMessage("Error deleting location: " + error.message);
    }
  };

  const extractCoordinatesFromUrl = (url) => {
    try {
      // Handle different Google Maps URL formats
      const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
      const match = url.match(regex);
      
      if (match) {
        const [, latitude, longitude] = match;
        return {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude)
        };
      }
      return null;
    } catch (error) {
      console.error("Error parsing coordinates:", error);
      return null;
    }
  };

  const handleMapUrlChange = (e) => {
    const url = e.target.value;
    setMapUrl(url);
    
    const coordinates = extractCoordinatesFromUrl(url);
    if (coordinates) {
      setEditingLocation({
        ...editingLocation,
        location: coordinates,
        mapUrl: url
      });
    }
    else {
      setEditingLocation({
        ...editingLocation,
        location: null,
        mapUrl: ""
      });
    }
  };

  const handleSubmit = async () => {
    const newLocation = {
      ...editingLocation,
      ...(imageUrl && { image: imageUrl }),
      mapUrl: mapUrl
    };
    console.log(newLocation);
    try {
      if (editingLocation.id) {
        await updateLocation2(editingLocation.id, newLocation);
      } else {
        await createLocation2(newLocation);
      }
      setIsEditing(false);
      setEditingLocation(null);
      setMapUrl(""); // Reset mapUrl after saving
      fetchLocations();
    } catch (error) {
      console.log(error);
      setErrorMessage("Error saving location: " + error.message);
    }
  };

  const handleImageUpload = (url) => {
    setImageUrl(url);
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <>
      <div className="mb-4">
        <button
          onClick={() => window.history.back()}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          <ArrowLeftIcon className="h-5 w-5 inline-block mr-2" />
          Back
        </button>
      </div>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Locations
          </h1>
          <div className="mb-4">
            <button
              onClick={() => {
                setIsEditing(true);
                setEditingLocation({
                  name: "",
                  location: null,
                  image: "",
                  base: permissions?.ALL ? "" : { id: baseId },
                  mapUrl: "" // Initialize mapUrl as empty string
                });
                setMapUrl(""); // Reset mapUrl when adding new location
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              <PlusIcon className="h-5 w-5 inline-block mr-2" />
              Add Location
            </button>
          </div>
          {isEditing && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setIsSaveModalOpen(true);
              }}
              className="mb-8 bg-white dark:bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                  id="name"
                  type="text"
                  value={editingLocation.name}
                  onChange={(e) =>
                    setEditingLocation({
                      ...editingLocation,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="mapUrl"
                >
                  Google Maps URL
                </label>
                <div className="flex mb-4">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                    id="mapUrl"
                    type="url"
                    placeholder="Paste Google Maps URL here"
                    value={mapUrl}
                    onChange={handleMapUrlChange}
                  />
                  {mapUrl && (
                    <button
                      type="button"
                      onClick={() => window.open(mapUrl, '_blank')}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    >
                      View
                    </button>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="location"
                >
                  Latitude: {editingLocation?.location?.latitude}, Longitude: {editingLocation?.location?.longitude}
                </label>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="base"
                >
                  Training Center
                </label>
                {permissions?.ALL ? (
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                    id="base"
                    value={editingLocation.base.id}
                    onChange={(e) =>
                      setEditingLocation({
                        ...editingLocation,
                        base: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select a Training Center</option>
                    {bases.map((base) => (
                      <option key={base.id} value={base.id}>
                        {base.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
                    type="text"
                    value={bases.find(base => base.id === baseId)?.name || ''}
                    disabled
                  />
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Upload Image
                </label>
                <ImageUploader onImageUpload={handleImageUpload} />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    setIsEditing(false);
                    setEditingLocation(null);
                    setMapUrl(""); // Reset mapUrl when canceling
                  }}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {locations?.map((location) => (
              <div
                key={location.id}
                className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg"
              >
                <img
                  className="w-full h-48 object-cover"
                  src={location.image}
                  alt={location.name}
                />
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                    {location.name}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
                    Coordinates: {location.location.latitude}, {location.location.longitude}
                  </p>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
                    Training Center: {bases.find((base) => base.id === location.base.id)?.name}
                  </p>
                  {/* {location.mapUrl && (
                    <a
                      href={location.mapUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-2 mt-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      Visit Location
                    </a>
                  )} */}
                  <div className="mt-4 flex space-x-3">
                    <button
                      onClick={() => handleEdit(location)}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <PencilIcon className="h-4 w-4 mr-2" />
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                        setLocationToDelete(location.id);
                      }}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hidden"
                    >
                      <TrashIcon className="h-4 w-4 mr-2" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Save Confirmation Modal */}
      {isSaveModalOpen && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                      id="modal-title"
                    >
                      Save Changes
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Are you sure you want to save these changes?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    handleSubmit();
                    setIsSaveModalOpen(false);
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsSaveModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                      id="modal-title"
                    >
                      Delete Training Center
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Are you sure you want to delete this Training Center? This
                        action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    handleDelete(locationToDelete);
                    setIsDeleteModalOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsDeleteModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                      id="modal-title"
                    >
                      Error
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setErrorMessage("")}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminLocation;
